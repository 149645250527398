import React from 'react';
import LargerGoldBlackBubbleEndingComp from './LargerMobileGoldBlackBubbleComp/LargerGoldBlackBubbleEndingComp';

import styles from './VetProfileSectionComp.module.css';

const EndingBoxCopyrightComp = (props) => {

    return (<div className={styles.vetProfileSectionCompContainer}>
        <LargerGoldBlackBubbleEndingComp
            cOneLineOne={props.cOneLineOne}
            cOneLineTwo={props.cOneLineTwo}
            cTwoLineOne={props.cTwoLineOne}
            cTwoLineTwo={props.cTwoLineTwo}
            cThreeLineOne={props.cThreeLineOne}
            cThreeLineTwo={props.cThreeLineTwo}>
        </LargerGoldBlackBubbleEndingComp>
        <span style={{
            color: '#E4D0B5',
            marginTop: '1.5rem',
            fontSize: '1rem'
            }}>© 2022 NightTable
        </span>
    </div>)
}

export default EndingBoxCopyrightComp;