import React from 'react';

import styles from './HeaderSectionComp.module.css';

const HeaderSectionComp = (props) => {

    return (<div className={styles.headerSectionCompContainer}>
        <span style={{
            fontSize: '3rem',
            color: '#E4D0B5',

        }}>NightTable</span>
        <span style={{
            marginTop: '1rem',
            color: '#E4D0B5'
        }}>Your Night, Your Way</span>
    </div>)
}

export default HeaderSectionComp;