import React from 'react';

import styles from './OuterPhoneBoxContainer.module.css';

import phoneImageGraphic from '../../../../assets/iphoneimage.png';

const OuterPhoneBoxContainer = (props) => {

    return (<div className={styles.outerPhoneBoxContainerComp}>
        <div className={styles.innerBlackBoxContainer}>
            <div className={styles.phoneImageContainer}>
                <img style={{
                    height: '110%'
                }} src={phoneImageGraphic}></img>
            </div>
            <div className={styles.textContainer}>
                <div className={styles.upperTextContainer}>
                    <div className={styles.firstTextRowContainer}>
                        <span>Vet profiles</span>
                    </div>
                    <div className={styles.textRowContainer}>
                        <span>Split tabs more efficiently</span>
                    </div>
                    <div className={styles.textRowContainer}>
                        <span>Request seats at other tables</span>
                    </div>
                    <div className={styles.textRowContainer}>
                        <span>Never be left to cover extra orders all on your own, ever again</span>
                    </div>
                </div>
                <button onClick={props.onRequestAppInvite} className={styles.notificationButtonStyle}>
                    <span className={styles.textDetailStyle} style={{fontSize: '1.5rem'}}>Let me know when the app is released!</span>
                </button>
            </div>
        </div>
    </div>)
}

export default OuterPhoneBoxContainer;

