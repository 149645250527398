import React, { useState, useEffect } from 'react';

import styles from './MainMobileLayoutComp.module.css';

import phoneImageGraphic from '../../assets/iphoneimage.png';
import OuterMobileBubbleComp from './OuterMobileBubbleComp/OuterMobileBubbleComp';
import VetProfileSectionComp from './VetProfileSectionComp/VetProfileSectionComp';
import BlackBackdrop from '../MainLayoutComp/BlackBackdrop/BlackBackdrop';
import EndingBoxCopyrightComp from './VetProfileSectionComp/EndingBoxCopyrightComp';

const MainMobileLayoutComp = (props) => {

    const firstBubbleFirstStringContent = "Create memorable experiences";
    const firstBubbleSecondStringContent = "Form meaningful relationships with valuable people";
    const secondBubbleFirstStringContent = "Join and share tables at a nightclub with";
    const secondBubbleSecondStringContent = "those who will enhance your social experience";

    const coupletOneLineOne = "Skip the line";
    const coupletOneLineTwo = "Form or join a table group using NightTable";
    const coupletTwoLineOne = "Whether you meet a companion,";
    const coupletTwoLineTwo = "or a potential business partner";
    const coupletThreeLineOne = "You'll walk out of the club";
    const coupletThreeLineTwo = "having formed meaningful relationships";

    const [ mobileModalShown, setMobileModalShown ] = useState(false);

    const [ isMobileDevice, setIsMobileDevice ] = useState(false);


    useEffect(() => {

        if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        
            setIsMobileDevice(true);
        }

    }, []);

    const laptopDeviceMobileStyle = {
        // display: 'flex',
        width: '100%',
        backgroundColor: 'black',
        flexDirection: 'column',
        position: 'relative',
        height: '100vh'
    };

    const mobileDeviceMobileStyle = {
        // display: 'flex',
        width: '100%',
        backgroundColor: 'black',
        flexDirection: 'column',
        position: 'relative',
        height: '100vh',
        height: '-webkit-fill-available',
        height: '-moz-available',
        height: 'fill-available',
    };


    const handleNotificationButtonClick = () => {

        setMobileModalShown(true);
    }

    const handleCancelModalRequest = () => {

        props.onResetStates();

        setMobileModalShown(false);

    }
    
    return (<div 
    className={styles.mainMobileLayoutCompContainer}
    style={ isMobileDevice ? mobileDeviceMobileStyle : laptopDeviceMobileStyle}>

        <div className={ mobileModalShown ? styles.mobileInnerMainContentModalOpenContainer : styles.mobileInnerMainContentContainer}>
                <div className={styles.mobileNightTableHeaderRowContainer}>
                    <span style={{
                        color: '#E4D0B5',
                        fontSize: '3rem'
                    }}>NightTable</span>

                </div>
                <div className={styles.subTitleRowContainer}>
                    <span style={{
                        color: '#E4D0B5'
                    }}>
                        Your Night, Your Way
                    </span>
                </div>

                <OuterMobileBubbleComp
                    firstLineContent={firstBubbleFirstStringContent}
                    secondLineContent={firstBubbleSecondStringContent}></OuterMobileBubbleComp>
                <OuterMobileBubbleComp
                    firstLineContent={secondBubbleFirstStringContent}
                    secondLineContent={secondBubbleSecondStringContent}></OuterMobileBubbleComp>
                <div className={styles.phoneImageContainer}>
                    <img style={{
                        pointerEvents: 'none',
                        height: '105%'
                    }} src={phoneImageGraphic}>
                    </img>
                </div>
                <VetProfileSectionComp
                    onNotificationButtonClick={handleNotificationButtonClick}></VetProfileSectionComp>
                <EndingBoxCopyrightComp
                    cOneLineOne={coupletOneLineOne}
                    cOneLineTwo={coupletOneLineTwo}
                    cTwoLineOne={coupletTwoLineOne}
                    cTwoLineTwo={coupletTwoLineTwo}
                    cThreeLineOne={coupletThreeLineOne}
                    cThreeLineTwo={coupletThreeLineTwo}>
                </EndingBoxCopyrightComp>

        </div>

        { mobileModalShown ? <BlackBackdrop
           successStateData={props.successStateData}
           loadingStateData={props.loadingStateData}
           errorStateData={props.errorStateData} 
           onLocalSubmitRequest={props.onLocalSubmitRequest}
           onCancelModalRequest={handleCancelModalRequest} ></BlackBackdrop> : null }
    </div>)
}

export default MainMobileLayoutComp;