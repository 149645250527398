import React from 'react';
import LargerInnerBlackBubbleComp from './LargerInnerBlackBubbleComp/LargerInnerBlackBubbleComp';

import styles from './LargerMobileGoldBlackBubbleComp.module.css';

const LargerMobileGoldBlackBubbleComp = (props) => {

    return (<div className={styles.largerMobileGoldBlackBubbleCompContainer}>
        <LargerInnerBlackBubbleComp
            onNotificationButtonClick={props.onNotificationButtonClick}></LargerInnerBlackBubbleComp>
    </div>)
}

export default LargerMobileGoldBlackBubbleComp;