import React, { useState } from 'react';
import BlackBackdrop from './BlackBackdrop/BlackBackdrop';
import DuoDialogBoxSectionComp from './DuoDialogBoxSectionComp/DuoDialogBoxSectionComp';
import HeaderSectionComp from './HeaderSectionComp/HeaderSectionComp';
import LowerBubbleSectionComp from './LowerBubbleSectionComp/LowerBubbleSectionComp';

import styles from './MainLayoutComp.module.css';
import PhoneShowcaseSectionComp from './PhoneShowcaseSectionComp/PhoneShowcaseSectionComp';

const MainLayoutComp = (props) => {

    const [ backDropOpen, setBackdropOpen ] = useState(false);

    const handleRequestAppInvite = () => {

        setBackdropOpen((state) => !state);
    }

    const handleCancelModalRequest = () => {

        props.onResetStates();

        setBackdropOpen((state) => !state);

    }

    return (<div className={styles.mainLayoutCompContainer}>

        <div className={styles.expandableMainInnerCompContainer}>
            <HeaderSectionComp></HeaderSectionComp>
            <DuoDialogBoxSectionComp></DuoDialogBoxSectionComp>
            <PhoneShowcaseSectionComp
                onRequestAppInvite={handleRequestAppInvite}></PhoneShowcaseSectionComp>
            <LowerBubbleSectionComp></LowerBubbleSectionComp>
        </div>
        { backDropOpen ? <BlackBackdrop
           successStateData={props.successStateData}
           loadingStateData={props.loadingStateData}
           errorStateData={props.errorStateData}
           onLocalSubmitRequest={props.onLocalSubmitRequest}
           onCancelModalRequest={handleCancelModalRequest} ></BlackBackdrop> : null }
    </div>)
}

export default MainLayoutComp;