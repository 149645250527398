import React from 'react';

import styles from './LargerInnerBlackBubbleComp.module.css';

const LargerInnerBlackBubbleComp = (props) => {

    return (<div className={styles.largerInnerBlackBubbleCompContainer}>
        <div className={styles.textLabelRowContainer}>
            <span className={styles.innerBlackBoxText}>Vet profiles</span>
        </div>
        <div className={styles.textLabelRowContainer}>
            <span className={styles.innerBlackBoxText}>Split tabs more efficiently</span>
        </div>
        <div className={styles.textLabelRowContainer}>
            <span className={styles.innerBlackBoxText}>Request seats at other tables</span>
        </div>
        <div className={styles.textLabelRowContainer}>
            <span className={styles.innerBlackBoxText}>Never be left to cover extra orders all on your own, ever again</span>
        </div>
        <div className={styles.buttonRowContainer}>
            <button onClick={props.onNotificationButtonClick} className={styles.notificationButtonStyle}>
                <div className={styles.innerTextButtonContainer}>
                    <span style={{
                        fontWeight: 'bold',
                        fontSize: '1.5rem'
                    }}>Let me know when the app is released!</span>
                </div>
            </button>
        </div>
    </div>)
}

export default LargerInnerBlackBubbleComp;