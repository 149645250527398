import React from 'react';
import OuterPhoneBoxContainer from './OuterPhoneBoxContainer/OuterPhoneBoxContainer';

import styles from './PhoneShowcaseSectionComp.module.css';

const PhoneShowcaseSectionComp = (props) => {

    return (<div className={styles.phoneShowcaseSectionCompContainer}>
        <OuterPhoneBoxContainer
            onRequestAppInvite={props.onRequestAppInvite}></OuterPhoneBoxContainer>
    </div>)
}

export default PhoneShowcaseSectionComp;