import React, { useState } from 'react';

import axios from 'axios';


import styles from './App.module.css';

import MainLayoutComp from './components/MainLayoutComp/MainLayoutComp';
import MainMobileLayoutComp from './components/MainMobileLayoutComp/MainMobileLayoutComp';

function App() {

  const [ loadingState, setLoadingState ] = useState(false);
  const [ errorState, setErrorState ] = useState(false);
  const [ successState, setSuccessState ] = useState(false); 

  const handleSubmitRequest = (userObject) => {

    axios.post('https://landing-page-server-nt.herokuapp.com/emaillist', userObject)
    .then(() => {

      setSuccessState(true);
      setErrorState(false);
      setLoadingState(false);

    })
    .catch((error) => {
      console.log(error);
      setErrorState(true);
      setLoadingState(false);
      setSuccessState(false);

    })

  };

  const handleStateReset = () => {

    setErrorState(false);
    setLoadingState(false);
    setSuccessState(false);

  }

  return (<div className={styles.outerGeneralContainer}>
    <MainMobileLayoutComp
      onResetStates={handleStateReset}
      successStateData={successState}
      loadingStateData={loadingState}
      errorStateData={errorState}
      onLocalSubmitRequest={handleSubmitRequest}></MainMobileLayoutComp>
    <MainLayoutComp
      onResetStates={handleStateReset}
      successStateData={successState}
      loadingStateData={loadingState}
      errorStateData={errorState}
      onLocalSubmitRequest={handleSubmitRequest}></MainLayoutComp>
    </div>
  );
}

export default App;
