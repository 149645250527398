import React from 'react';

import styles from './LargerInnerBlackBubbleComp.module.css';

const LargerInnerBlackEndingBubbleComp = (props) => {

    return (
        <div className={styles.largerInnerBlackBubbleCompContainer}>
            <div className={styles.textLabelRowContainer}>
                <span className={styles.innerBlackBoxText} style={{fontSize: '1rem'}}>{props.cOneLineOne}</span>
                <span className={styles.innerBlackBoxText} style={{fontSize: '1rem'}}>{props.cOneLineTwo}</span>
            </div>
            <p></p>
            <div className={styles.textLabelRowContainer}>
                <span className={styles.innerBlackBoxText} style={{fontSize: '1rem'}}>{props.cTwoLineOne}</span>
                <span className={styles.innerBlackBoxText} style={{fontSize: '1rem'}}>{props.cTwoLineTwo}</span>
            </div>
            <p></p>
            <div className={styles.textLabelRowContainer}>
                <span className={styles.innerBlackBoxText} style={{fontSize: '1rem'}}>{props.cThreeLineOne}</span>
                <span className={styles.innerBlackBoxText} style={{fontSize: '1rem'}}>{props.cThreeLineTwo}</span>
            </div>
            
        </div>
)
}


export default LargerInnerBlackEndingBubbleComp;