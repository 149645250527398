import React from 'react';
import InnerBlackBubbleComp from './InnerBlackBubbleComp/InnerBlackBubbleComp';

import styles from './MobileGoldBlackBubbleComp.module.css';

const MobileGoldBlackBubbleComp = (props) => {

    return (<div className={styles.testContainer}>
        <InnerBlackBubbleComp
            firstLineContent={props.firstLineContent}
            secondLineContent={props.secondLineContent}></InnerBlackBubbleComp>
    </div>)
}

export default MobileGoldBlackBubbleComp;