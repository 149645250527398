import React from 'react';
import AppInviteModal from './AppInviteModal/AppInviteModal';

import styles from './BlackBackdrop.module.css';

const BlackBackdrop = (props) => {

    return (<div className={styles.blackBackdropContainer}>
        <AppInviteModal
            successStateData={props.successStateData}
            loadingStateData={props.loadingStateData}
            errorStateData={props.errorStateData}
            onLocalSubmitRequest={props.onLocalSubmitRequest}
            onCancelModalRequest={props.onCancelModalRequest}></AppInviteModal>
    </div>)
}

export default BlackBackdrop;