import React from 'react';

import styles from './GenericInputComp.module.css';

const GenericInputComp = (props) => {



    const handleGenericInputChange = () => {

        console.log("We are handling the local input change");
    }

    return (<div className={styles.genericInputCompContainer}>
        <span style={{
            marginRight: '0.2rem',
            fontSize: '1.1rem',
            color: '#E4D0B5',
            minWidth: '6rem'
        }}>{props.label}</span>
        <input onChange={props.onInputChange} className={styles.listInputStyle}></input>
    </div>)
}

export default GenericInputComp;