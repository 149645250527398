import React from 'react';

import styles from './GoldBorderedBlackBoxComp.module.css';

const GoldBorderedBlackBoxComp = (props) => {

    return (<div style={{
        marginBottom: props.firstBox ? '2rem' : '0rem'
    }} className={styles.goldBorderedBlackBoxCompContainer}>
        <div className={styles.innerBlackBoxContainer}>
            <span style={{
                width: '60%',
                textAlign: 'center',
                marginBottom: '0.3rem'
            }}>{props.firstLineText}</span>
            <span>
                {props.secondLineText}
            </span>
        </div>
    </div>)
}

export default GoldBorderedBlackBoxComp;