import React from 'react';
import LargerInnerBlackEndingBubbleComp from './LargerInnerBlackBubbleComp/LargerInnerBlackEndingBubbleComp';

import styles from './LargerMobileGoldBlackBubbleComp.module.css';

const LargerGoldBlackBubbleEndingComp = (props) => {

    return (<div className={styles.largerMobileGoldBlackBubbleCompContainer}>
        <LargerInnerBlackEndingBubbleComp
            cOneLineOne={props.cOneLineOne}
            cOneLineTwo={props.cOneLineTwo}
            cTwoLineOne={props.cTwoLineOne}
            cTwoLineTwo={props.cTwoLineTwo}
            cThreeLineOne={props.cThreeLineOne}
            cThreeLineTwo={props.cThreeLineTwo}>
        </LargerInnerBlackEndingBubbleComp>
    </div>)
}

export default LargerGoldBlackBubbleEndingComp;