import React from 'react';
import MobileGoldBlackBubbleComp from './MobileGoldBlackBubbleComp/MobileGoldBlackBubbleComp';

import styles from './OuterMobileBubbleComp.module.css';

const OuterMobileBubbleComp = (props) => {

    return (<div className={styles.outerMobileBubbleContainer}>
        <MobileGoldBlackBubbleComp
            firstLineContent={props.firstLineContent}
            secondLineContent={props.secondLineContent}
            ></MobileGoldBlackBubbleComp>
    </div>)
}

export default OuterMobileBubbleComp;