import React from 'react';

import styles from './PhoneNumberInputComp.module.css';

const PhoneNumberInputComp = (props) => {

    return (<div className={styles.phoneNumberInputCompContainer}>
        <span style={{
            marginRight: '0.2rem',
            fontSize: '1.1rem',
            color: '#E4D0B5',
            minWidth: '6rem'
        }}>Phone Number:</span>
        <div className={styles.outerInputContainer}>
            <input
                 onChange={props.onLocalCountryCodeChange} 
                 className={styles.countryCodeInput}>
            </input>
            <input
                onChange={props.onLocalMainPhoneNumberChange} 
                className={styles.mainPhoneNumberInput}>
            </input>
        </div>
    </div>)
}

export default PhoneNumberInputComp;