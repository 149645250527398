import React from 'react';

import styles from './LargeGoldBorderedBlackBoxComp.module.css';

const LargeGoldBorderedBlackBoxComp = (props) => {

    return (<div className={styles.largeGoldBorderedBlackBoxCompContainer}>
        <div className={styles.innerBlackBoxContainer}>
            <div className={styles.miniParagraphRow}>
                <span>
                    Skip the line
                </span>
                <span>
                    Form or join a table group using NightTable
                </span>
            </div>
            <div className={styles.miniParagraphRow}>
                <span>
                    Whether you meet a companion,
                    
                </span>
                <span>
                    or a potential business partner
                </span>
            </div>
            <div className={styles.miniParagraphRow}>
                <span>
                    You'll walk out of the club 
                </span>
                <span>
                    having formed meaningful relationships
                </span>
            </div>
        </div>
    </div>)
}

export default LargeGoldBorderedBlackBoxComp;