import React, { useState } from 'react';
import GenericInputComp from './GenericInputComp/GenericInputComp';

import styles from './InnerAppInviteBlackContainerComp.module.css';
import PhoneNumberInputComp from './PhoneNumberInputComp/PhoneNumberInputComp';

const InnerAppInviteBlackContainerComp = (props) => {

    const [ firstName, setFirstName ] = useState("");
    const [ lastName, setLastName ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ countryCode, setCountryCode ] = useState("");
    const [ mainPhoneNumber, setMainPhoneNumber ] = useState("");

    const handleFirstNameInputChange = (e) => {

        setFirstName(e.target.value);
    }

    const handleLastNameInputChange = (e) => {

        setLastName(e.target.value);
    }

    const handleEmailInputChange = (e) => {

        setEmail(e.target.value);

    }

    const handleCountryCodeChange = (e) => {

        setCountryCode(e.target.value);

    }

    const handleMainPhoneNumberChange = (e) => {

        setMainPhoneNumber(e.target.value);

    }

    const handleSubmitRequest = () => {

        const currentFirstNameSnapshot = firstName;
        const currentLastNameSnapshot = lastName;
        const currentEmailSnapshot = email;
        const currentCountryCodeSnapshot = countryCode; 
        const currentMainPhoneNumberSnapshot = mainPhoneNumber;

        const userObject = {
            firstName: currentFirstNameSnapshot,
            lastName: currentLastNameSnapshot,
            email: currentEmailSnapshot,
            countryCode: currentCountryCodeSnapshot,
            mainPhoneNumber: currentMainPhoneNumberSnapshot
        }

        props.onLocalSubmitRequest(userObject);

    }


    return (<div className={styles.innerAppInviteBlackContainerComp}>
        { !props.errorStateData 
            && !props.loadingStateData
            && !props.successStateData ? <React.Fragment><div className={styles.emailListHeaderRowContainer}>
            <span style={{
                color: '#E4D0B5',
                fontSize: '1.5rem'
            }}>Get Notified When App Is Released!</span>
        </div>
        <GenericInputComp
            onInputChange={handleFirstNameInputChange}
            label="First Name:"></GenericInputComp>
        <GenericInputComp
            onInputChange={handleLastNameInputChange}
            label="Last Name:"></GenericInputComp>
        <GenericInputComp
            onInputChange={handleEmailInputChange}
            label="Email:"></GenericInputComp>
        <PhoneNumberInputComp
            onLocalCountryCodeChange={handleCountryCodeChange}
            onLocalMainPhoneNumberChange={handleMainPhoneNumberChange}
            ></PhoneNumberInputComp>
        <div className={styles.countryCodeLabelRowNotifier}>
            <span
            style={{
                marginLeft: '9.4rem',
                color: '#E4D0B5',
                fontSize: '0.9rem'
            }}>(country code)</span>
        </div>
        <div className={styles.lowerButtonRowContainer}>
            <button style={{
                marginRight: '0.3rem'
            }} onClick={props.onCancelModalRequest} className={styles.lowerButtonStyle}>
                <span>Cancel</span>
            </button>
            <button onClick={handleSubmitRequest} className={styles.lowerButtonStyle}>
                <span>Submit</span>
            </button>
        </div></React.Fragment> : null }
        { props.loadingStateData ? <React.Fragment>
            <div style={{
                display: 'flex',
                height: '100%',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <span style={{color: 'white'}}>
                    The data is loading
                </span> 
            </div>
            </React.Fragment> : null}
        { props.errorStateData ? <React.Fragment>
            <div style={{
                display: 'flex',
                height: '100%',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <span style={{
                    fontSize: '1.2rem',
                    color: '#E4D0B5'}}>
                    Something went wrong
                </span>  
                <button 
                onClick={props.onCancelModalRequest} 
                style={{
                    marginTop: '1rem',
                    outlineStyle: 'none',
                    borderStyle: 'none',
                    backgroundColor: '#E4D0B5',
                    width: '6rem',
                    height: '2rem',
                    cursor: 'pointer',
                    borderRadius: '0.2rem' 
                }}> 
                    <span>Close</span>
                </button>
            </div>
        </React.Fragment> : null}
        { props.successStateData ? <React.Fragment>
            <div style={{
                display: 'flex',
                height: '100%',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <span style={{
                    fontSize: '1.2rem',
                    color: '#E4D0B5'}}>
                    Thank you!
                </span>  
                <button 
                onClick={props.onCancelModalRequest} 
                style={{
                    marginTop: '1rem',
                    outlineStyle: 'none',
                    borderStyle: 'none',
                    backgroundColor: '#E4D0B5',
                    width: '6rem',
                    height: '2rem',
                    cursor: 'pointer',
                    borderRadius: '0.2rem' 
                }}> 
                    <span>Close</span>
                </button>
            </div>
        </React.Fragment> : null}
    </div>)
}

export default InnerAppInviteBlackContainerComp;