import React from 'react';
import LargeGoldBorderedBlackBoxComp from '../../../UI/LargeGoldBorderedBlackBoxComp/LargeGoldBorderedBlackBoxComp';


import styles from './LowerBubbleSectionComp.module.css';

const LowerBubbleSectionComp = (props) => {

    const bubbleThreeContent = "Skip the line.\nForm";

    return (<div className={styles.lowerBubbleSectionCompContainer}>
        <LargeGoldBorderedBlackBoxComp
            content={bubbleThreeContent}></LargeGoldBorderedBlackBoxComp>
        <span style={{
            color: '#E4D0B5',
            marginTop: '1.5rem'
        }}>© 2022 NightTable</span>
    </div>)
}

export default LowerBubbleSectionComp;