import React from 'react';
import LargerMobileGoldBlackBubbleComp from './LargerMobileGoldBlackBubbleComp/LargerMobileGoldBlackBubbleComp';

import styles from './VetProfileSectionComp.module.css';

const VetProfileSectionComp = (props) => {

    return (<div className={styles.vetProfileSectionCompContainer}>
        <LargerMobileGoldBlackBubbleComp
            onNotificationButtonClick={props.onNotificationButtonClick}></LargerMobileGoldBlackBubbleComp>
    </div>)
}

export default VetProfileSectionComp;