import React from 'react';
import GoldBorderedBlackBoxComp from '../../../UI/GoldBorderedBlackBoxComp/GoldBorderedBlackBoxComp';

import styles from './DuoDialogBoxSectionComp.module.css';

import partyPicGraphicOne from '../../../assets/images/partypicone.png';
import partyPicGraphicTwo from '../../../assets/images/partypictwo.png';
import bottlePicGraphicOne from '../../../assets/images/bottlepicone.png';

const DuoDialogBoxSectionComp = (props) => {

    const bubbleOneFirstLine = `Create memorable experiences.`;

    const bubbleOneSecondLine = `Form meaningful relationships with valuable people`;

    const bubbleTwoFirstLine = 'Join and share tables at a nightclub with';

    const bubbleTwoSecondLine = 'those who will enhance your social experience';


    return (<div className={styles.duoDialogBoxSectionCompContainer}>
        <GoldBorderedBlackBoxComp
            firstBox
            firstLineText={bubbleOneFirstLine}
            secondLineText={bubbleOneSecondLine}></GoldBorderedBlackBoxComp>
        <GoldBorderedBlackBoxComp
            firstLineText={bubbleTwoFirstLine}
            secondLineText={bubbleTwoSecondLine}></GoldBorderedBlackBoxComp>
        <div className={styles.pictureBackOverlayContainer}>
            <div className={styles.leftImageContainer}>
                <img className={styles.imageStyle} src={partyPicGraphicOne}>
                </img>
            </div>
            <div className={styles.middleImageContainer}>
                <img className={styles.imageStyle} src={bottlePicGraphicOne}>
                </img>
            </div>
            <div className={styles.rightImageContainer}>
                <img className={styles.imageStyle} src={partyPicGraphicTwo}>
                </img>
            </div>
        </div>
    </div>)
}

export default DuoDialogBoxSectionComp;