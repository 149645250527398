import React from 'react';

import styles from './AppInviteModal.module.css';
import InnerAppInviteBlackContainerComp from './InnerAppInviteBlackContainerComp/InnerAppInviteBlackContainerComp';

const AppInviteModal = (props) => {

    return (
        <div className={styles.appInviteModalContainer}>
            <InnerAppInviteBlackContainerComp
                successStateData={props.successStateData}
                loadingStateData={props.loadingStateData}
                errorStateData={props.errorStateData}
                onLocalSubmitRequest={props.onLocalSubmitRequest}
                onCancelModalRequest={props.onCancelModalRequest}></InnerAppInviteBlackContainerComp>
        </div>
    )
}

export default AppInviteModal;