import React from 'react';

import styles from './InnerBlackBubbleComp.module.css';

const InnerBlackBubbleComp = (props) => {

    return (<div className={styles.innerBlackBubbleCompContainer}>
        <span style={{marginBottom: '0.2rem', fontSize: '1rem'}} 
            className={styles.infoTextStyle}>{props.firstLineContent}
        </span>
        <span style={{fontSize: '1rem'}}className={styles.infoTextStyle}>{props.secondLineContent}</span>
    </div>)
}

export default InnerBlackBubbleComp;